.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#DataTables_Table_0_wrapper{
  max-width: 100% !important;
  width: 100% !important;
  overflow-x: scroll;
}
.row{
  /* max-width: 100% !important; */
}
.dtgrid{
  
}
.text-sm .content-header h1{
  color: #FFFFFF !important;
}
.content{
  /* height: 100vh; */
  padding-bottom: 40px;
}

.card{
  background-color: #fffffff2 !important;
  
}
.content-wrapper{
  /* background:none !important; */
  background-image: url(./assets/images/events.jpg) !important;
  background-size: cover;
  background-repeat:no-repeat;

}

.border-box {
  box-shadow: 2px 5px 13px 2px rgb(0 0 0 / 6%);
  /* border-radius: 20px; */
  padding: 10px;
  /* margin-top: 5px; */
  margin-bottom: 10px;
}
.collapse .form-inline{
float: right;
}
.collapse h6{
  /* padding-left:50px ;
  padding-top:10px; */
  color: #000000;
} 
.bg-tbheader{
  background-color:#d45f55 !important; 
}
.respSSL{
  color:red;
}
.credit-card-info-wrapper img{
  max-width: 100px;
}
.mob-widgets{
  height: 50px;
  width: 50px;
}
@media screen and (max-width: 600px) {
  .main-footer {
    display: none;
  }
}
._brFile {
  /* border-radius: 90%; */
  width: 40px !important;
  height: 40px !important;
}
.updImg{
  display: none;
}
.react-confirm-alert{
  width: 80%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dash_opt h3{
  min-height:20vh; 
}
.vcenter-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.callout a{
 text-decoration: none !important;
}
.post{
  color:#000000 !important;
}
.box-t-l-r{
  box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 13%);
  padding-top: 10px;
  padding-bottom: 10px;
  
}
.link-color:hover{
  color: #007bff !important;
}
.link-color{
  color: #007bff !important;
}
.nav-item i{
  padding-right: 5px;
}
.entity_icon_img{
  max-height: 50px;
  width: auto;
}
.updImgThumb {
  display: none;
}
.updImgThumbMulti{
  display: none;
}
.upd_img_thumg{
  max-height: 100px;
  width: auto;
}
.image_thumb_parent img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  height: auto;
}
.imgLibThumb img {
  float: left;
  object-fit: cover;
}
.image_thumb_parent .fa-close {
  position: absolute;
  color: #000;
  top: 0px;
  right: 0px;
  margin-right: -2px;
  z-index: 9;
  background: #fff;
  font-size: 16px;
}
.boxsizingBorder {
  float: left;
  width: 100%;
}
.image_thumb_parent .fa-close {
  position: absolute;
  color: #000;
  top: 0px;
  right: 0px;
  z-index: 9;
  background: #fff;
  font-size: 16px;
}
.img-responsive{
  max-width: 100%;
}
.select2{
  width: 100% !important;
}
.dropdown-menu{
  z-index: 9999 !important;
  overflow: visible;
}
.dropdown-item{
  z-index: 9999!important;
  
}

 .table-responsive {
  overflow:inherit;
}

#tbData{
  min-height: 100vh;
}
.white{
  color:#fff;
}



@import url(./assets/css/custom_css.css);
@import url(./assets/css/paggination.css);
@import url(https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css);
@import url(./assets/css/desktop.css);
