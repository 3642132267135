.form-group-sm{
    margin-bottom: 0.3rem !important;
}
.form-control:focus{
    border-width: 2px;
}
.btn:focus,.btn:hover{
    border-width: 2px;
    border-color: rgb(242, 88, 88) !important;
}
.navbar{
  padding-top: 0.3rem !important;
  padding-right: 0.5rem;
  padding-bottom: 0.2rem !important;
  padding-left: 0.5rem;
    
}
  