
@media only screen and (min-width: 800px) {
    .nav-menu-top-item{
        color:white !important;
    }
}
@media (max-width:800px){
    .ml-auto{
        display: none !important;
    }
    .ml-auto .nav-item .nav-link .white{
        color: #000;
    }
    .brand-image{
        max-width: 100px;
    }
    .img-circle{
        max-height: 100px;
    }
    .ml-auto .nav-item{
        /* display: flex;
        flex-direction: column; */
        /* max-width: 70%; */
    }

}
#nav-close-btn{
    z-index: 9999;
}